/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FadeInUnderLineListType } from "components/molecules/FadeInUnderLineList/FadeInUnderLineList";
import { SNSTextIconsType } from "components/molecules/SNSTextIcons/SNSTextIcons";
import { Footer, FooterSlotProps } from "components/organisms/Footer/Footer";
import { Header } from "components/organisms/Header/Header";
import { NavMenuV2 } from "components/organisms/NavMenuV2/NavMenuV2";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { defaultTheme } from "styles/theme";
import { FadeIn } from "utils/Animation/FadeIn";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";

export type FadeInOneColumnLayoutTemplateSlotProps = {
  header: {
    logo: {
      dark: boolean;
    };
    hamburgerButton: {
      onClick: React.MouseEventHandler<HTMLButtonElement>;
      open: boolean;
      color: string;
      openColor: string;
    };
    changeThemeButton: {
      dark?: boolean;
      styleProps?: {
        light?: {
          color?: string;
        };
        dark?: {
          color?: string;
        };
      };
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
  };
  navMenu: {
    show: boolean;
    logo: {
      onLogoClick: () => void;
    };
    overlay: {
      onOverlayClick: () => void;
    };
    navItems: {
      items: FadeInUnderLineListType[];
      onNavItemClick: () => void;
    };
    footer: {
      iconProps: SNSTextIconsType;
      uiLibraryLink: string;
    };
  };
  footer: FooterSlotProps;
};

export type FadeInOneColumnLayoutTemplateProps = {
  slotProps: FadeInOneColumnLayoutTemplateSlotProps;
  children: React.ReactNode;
  duration?: number;
  mediaQuery: MediaQuery;
};

export const FadeInOneColumnLayoutTemplate: React.FC<FadeInOneColumnLayoutTemplateProps> =
  ({
    slotProps,
    children,
    duration = 0.5,
    mediaQuery,
  }: FadeInOneColumnLayoutTemplateProps) => {
    return (
      <FadeIn duration={duration}>
        <BaseWrapper id="layout">
          <header>
            <Header id="header" slotProps={slotProps.header} />
            <nav>
              <NavMenuV2
                show={slotProps.navMenu.show}
                slotProps={slotProps.navMenu}
              />
            </nav>
          </header>
          <main>{children}</main>
          <footer>
            <Footer
              id="footer"
              slotProps={{
                ...slotProps.footer,
                pageTop: {
                  show: slotProps.footer?.pageTop?.show,
                  bgColor: slotProps.footer?.pageTop?.bgColor,
                  color: slotProps.footer?.pageTop?.color,
                  cssOverride: slotProps.footer?.pageTop?.cssOverride,
                },
                wrapper: {
                  bgColor: slotProps.footer.wrapper.bgColor,
                  textColor: slotProps.footer.wrapper.textColor,
                  style: css`
                    padding-top: ${defaultTheme.spacing.footer.top};
                    padding-bottom: ${defaultTheme.spacing.footer.bottom};
                  `,
                },
              }}
              mediaQuery={mediaQuery}
            />
          </footer>
        </BaseWrapper>
      </FadeIn>
    );
  };
