/** @jsxImportSource @emotion/react */
import { css, keyframes, SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import { useAnimation } from "../../../hooks/useAnimation";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: ReactNode;
  show?: boolean;
  color?: string;
  duration?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const Overlay: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  show = false,
  color = "#222222CC",
  duration = 0.5, // sec
  onClick = () => {},
}: Props) => {
  const commonStyles = css`
    background-color: ${color};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `;

  const slideIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  const slideOut = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  `;

  const { animationStyles } = useAnimation({
    show,
    commonStyles: commonStyles,
    showKeyframes: slideIn,
    hideKeyframes: slideOut,
    durationSec: duration,
  });

  return (
    <button
      css={[
        animationStyles,
        cssOverride,
        css`
          cursor: default;
        `,
      ]}
      className={"" + (className ? ` ${className}` : "")}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
