/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const VerticalSymbolTextLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 100,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <svg
      className={`${className ? className : ""}`}
      css={[
        cssOverride,
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
      ]}
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 602 310.88"
    >
      <g>
        <polygon points="33.63 250.8 63.22 221.32 50.07 221.32 24.53 247.29 24.53 221.32 14.64 221.32 14.64 286.77 24.53 286.77 24.53 259.74 26.57 257.63 50.17 286.77 63.46 286.77 33.63 250.8" />
        <path d="M117.46,217.13,85.57,286.77H96.41l7.24-15.9h27.71l7.29,15.9h10.74Zm9.78,44.51H107.79l9.66-22.2Z" />
        <polygon points="239.65 221.32 222.67 264.91 204.58 218.1 186.65 264.89 169.59 221.32 158.81 221.32 186.69 291.16 204.59 244.54 222.64 290.98 250.39 221.32 239.65 221.32" />
        <path d="M295.06,217.13l-31.88,69.64H274l7.24-15.9H309l7.29,15.9H327Zm9.78,44.51H285.4l9.66-22.2Z" />
        <rect x="362.23" y="221.32" width="9.89" height="65.45" />
        <path d="M440.1,229a24.43,24.43,0,0,1,16.28,6l1.65,1.41v-12l-.51-.29a34.52,34.52,0,0,0-32.34-.43,37.3,37.3,0,0,0-12.33,10.13,30.53,30.53,0,0,0-6.62,19.27A34.75,34.75,0,0,0,409,266.93a33.89,33.89,0,0,0,18.49,18.57,33.29,33.29,0,0,0,13,2.63,34,34,0,0,0,17.06-4.61l.5-.29v-12l-1.64,1.36c-5,4.18-10.5,6.3-16.29,6.3a22.93,22.93,0,0,1-16.81-7.28c-4.76-4.84-7.17-11-7.17-18.39a23.37,23.37,0,0,1,7.17-16.92A23,23,0,0,1,440.1,229Z" />
        <polygon points="530.69 221.32 530.69 246.8 502.4 246.8 502.4 221.32 492.47 221.32 492.47 286.77 502.4 286.77 502.4 256.19 530.69 256.19 530.69 286.77 540.54 286.77 540.54 221.32 530.69 221.32" />
        <rect x="582.58" y="221.32" width="9.89" height="65.45" />
        <path d="M237.44,113.56A67,67,0,0,1,337.83,27l-1.77,2a64.28,64.28,0,0,0-96.32,83Z" />
        <path d="M294.06,144.7h-.36a67,67,0,0,1-43.47-16.3l1.77-2a64.28,64.28,0,0,0,96.33-83l2.29-1.45A67,67,0,0,1,294.06,144.7Z" />
        <polygon
          points="300.03 86.8 279.87 104.25 300.03 121.69 300.03 86.8"
          style={{ fill: "none" }}
        />
        <polygon
          points="302.74 124.04 311.8 131.88 311.8 76.61 302.74 84.45 302.74 124.04"
          style={{ fill: "none" }}
        />
        <polygon
          points="314.5 134.22 335.59 152.47 335.59 56.02 314.5 74.27 314.5 134.22"
          style={{ fill: "none" }}
        />
        <path d="M354.84,23.52l-85.1,73.35V41.61H267v57.6l-24.62,21.22,1.77,2.05L267,102.79V158h2.71V100.45l.8-.69L338.3,158.4v-117l18.31-15.78ZM300,121.7l-20.16-17.45L300,86.8Zm11.77,10.18L302.74,124V84.45l9.06-7.84Zm23.79,20.59L314.5,134.22v-60L335.59,56Z" />
      </g>
    </svg>
  );
};
