/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";

type Props = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
  height?: number;
};

export const FullScreenCenteredWrapper: React.FC<Props> = ({
  children,
  cssOverride,
  height = 100,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          /* full-screen */
          width: 100%;
          height: ${height}vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `,
      ]}
    >
      {children}
    </div>
  );
};
