/** @jsxImportSource @emotion/react */
import { LogoHero } from "components/organisms/LogoHero/LogoHero";
import React from "react";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { FullScreenCenteredWrapper } from "utils/Wrapper/FullScreenCenteredWrapper/FullScreenCenteredWrapper";

export type HomeTemplateSlotProps = {
  hero: React.ComponentProps<typeof LogoHero>;
};

type Props = {
  id: string;
  slotProps: HomeTemplateSlotProps;
};

export const HomeTemplate: React.FC<Props> = ({ id, slotProps }) => {
  const {
    panels,
    delayTimeSec = 0.2,
    dark = false,
    slotProps: logoHeroSlotProps,
  } = slotProps.hero;

  return (
    <BaseWrapper id={id}>
      <FullScreenCenteredWrapper>
        <BaseInner>
          <LogoHero
            panels={panels}
            delayTimeSec={delayTimeSec}
            dark={dark}
            slotProps={logoHeroSlotProps}
          />
        </BaseInner>
      </FullScreenCenteredWrapper>
    </BaseWrapper>
  );
};
