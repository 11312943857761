/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { NewWindowIcon } from "components/atoms/NewWindowIcon/NewWindowIcon";
import { SNSTextIconsType } from "components/molecules/SNSTextIcons/SNSTextIcons";
import {
  FadeInOneColumnLayoutTemplate,
  FadeInOneColumnLayoutTemplateSlotProps,
} from "components/templates/FadeInOneColumnLayoutTemplate/FadeInOneColumnLayoutTemplate";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useNavMenu } from "hooks/useNavMenu";
import { useTheme } from "hooks/useTheme";
import { useWindowScrollToTop } from "hooks/useWindowScrollToTop";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ANIMATAION_DURATION, convertDurationToSeconds } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";
import { sleep } from "utils/sleep";

type Props = {
  children: React.ReactNode;
};
export const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const { open, toggleOpen } = useNavMenu();
  const { toggleThemeState: classToggle, mode, theme } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const location = useLocation();

  // urlの変更を検知し、スクロールトップまで戻す
  const { scrollToWindowTop: scrollToTop, locatonPath } =
    useWindowScrollToTop();
  React.useEffect(() => {
    scrollToTop();
  }, [locatonPath]);

  const handleHamburgerButtonClick = useCallback(() => {
    toggleOpen();
  }, [toggleOpen]);

  const handleChangeThemeButtonClick = useCallback(() => {
    classToggle();
  }, [classToggle]);

  const handleLogoButtonClick = useCallback(() => {
    toggleOpen();
  }, [toggleOpen]);

  const handleOverlayClick = useCallback(() => {}, []);

  const handleNavItemClick = useCallback(() => {
    toggleOpen();
  }, [toggleOpen]);

  const footerSns: SNSTextIconsType = useMemo(
    () => ({
      github: {
        href: "https://github.com/Kawaichi0228",
      },
    }),
    []
  );

  const duration = useMemo(
    () => convertDurationToSeconds(ANIMATAION_DURATION),
    []
  );

  const [pageTopShow, setPageTopShow] = React.useState(false);
  const showFooterPageTopPaths = useMemo(
    () => [
      constants.pages.about.url,
      constants.pages.product.url.index,
      constants.pages.product.url.talkstock,
      constants.pages.product.url.portfolio,
      constants.pages.product.url.kawaichiBlog,
      constants.pages.product.url.fitscreenwindow,
      constants.pages.product.url.shotoku,
      constants.pages.design.url,
    ],
    []
  );
  useEffect(() => {
    const toggleShowPageTop = async () => {
      await sleep(ANIMATAION_DURATION);
      setPageTopShow(showFooterPageTopPaths.includes(location.pathname));
    };
    if (location.pathname) {
      toggleShowPageTop();
    }
  }, [location.pathname]);

  const slotProps: FadeInOneColumnLayoutTemplateSlotProps = useMemo(
    () => ({
      header: {
        logo: {
          dark: mode === "dark",
        },
        hamburgerButton: {
          onClick: handleHamburgerButtonClick,
          open,
          color: mode === "dark" ? theme.palette.text : theme.palette.text,
          openColor: theme.palette.common.white,
        },
        changeThemeButton: {
          dark: mode === "dark",
          styleProps: {
            light: {
              color: open ? theme.palette.common.white : theme.palette.text,
            },
            dark: {
              color: theme.palette.text,
            },
          },
          onClick: handleChangeThemeButtonClick,
        },
      },
      navMenu: {
        show: open,
        logo: {
          onLogoClick: handleLogoButtonClick,
        },
        overlay: {
          onOverlayClick: handleOverlayClick,
        },
        navItems: {
          items: [
            {
              path: constants.pages.about.url,
              scrollToID: constants.pages.about.scrollToID,
              children: constants.pages.about.title,
            },
            {
              path: constants.pages.product.url.index,
              scrollToID: constants.pages.product.scrollToID,
              children: constants.pages.product.title,
            },
            {
              path: constants.pages.software.url,
              scrollToID: constants.pages.software.scrollToID.index,
              children: constants.pages.software.title,
            },
            {
              path: constants.pages.design.url,
              scrollToID: constants.pages.design.scrollToID,
              children: constants.pages.design.title,
            },
            {
              path: constants.pages.contact.url,
              scrollToID: constants.pages.contact.scrollToID,
              children: constants.pages.contact.title,
            },
            {
              path: constants.links.kawaichiBlog,
              children: (
                <span
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span>BLOG</span>
                  <NewWindowIcon
                    size={pc ? 32 : 28}
                    color={theme.palette.common.white}
                  />
                </span>
              ),
            },
          ],
          onNavItemClick: handleNavItemClick,
        },
        footer: {
          iconProps: footerSns,
          uiLibraryLink: constants.links.portfolioStorybook,
        },
      },
      footer: {
        pageTop: {
          show: pageTopShow,
          bgColor: theme.palette.primary,
          color: theme.palette.contrastText,
          cssOverride: css`
            margin-top: ${defaultTheme.spacing.lg};
          `,
        },
        wrapper: {
          bgColor: theme.palette.background,
          textColor: theme.palette.gray.text,
        },
        snsIcons: {
          color: theme.palette.gray.text,
          iconProps: footerSns,
        },
        copyRight: {
          text: constants.copyRight.text,
        },
      },
    }),
    [
      theme,
      open,
      handleChangeThemeButtonClick,
      handleHamburgerButtonClick,
      handleLogoButtonClick,
      handleNavItemClick,
      handleOverlayClick,
      footerSns,
      pc,
      pageTopShow,
    ]
  );

  return (
    <FadeInOneColumnLayoutTemplate
      duration={duration}
      slotProps={slotProps}
      mediaQuery={mediaQuery}
    >
      {children}
    </FadeInOneColumnLayoutTemplate>
  );
};
