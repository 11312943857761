/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import { useTheme } from "hooks/useTheme";
import { useMemo } from "react";
import { mixin } from "./mixin";

export const GlobalStyles: React.FC = () => {
  const { theme } = useTheme();

  const globalStyles = useMemo(
    () => css`
      body {
        ${mixin.changeTheme}
        background-color: ${theme.palette.background};
        color: ${theme.palette.text};
        font-family: "Montserrat", "roboto", "Noto Sans JP",
          "Hiragino Kaku Gothic ProN", "Hiragino Sans", "meiryo", "arial",
          sans-serif;
        line-height: 3rem;
      }
    `,
    [mixin, theme]
  );

  return <Global styles={globalStyles} />;
};
