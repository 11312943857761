/** @jsxImportSource @emotion/react */
import { SerializedStyles, css, keyframes } from "@emotion/react";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import { UnderLineButton } from "components/atoms/UnderLineButton/UnderLineButton";

const styles = css`
  width: fit-content;
  opacity: 0;
  font-size: 0.875rem;
  line-height: 2rem;
`;

const fadeIn = keyframes`
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const animation = (delayTime = 0.2, fadeInTime = 2.1, index = 0) => {
  const _delayTime = delayTime + "s";
  const _fadeInTime = fadeInTime + "s";
  return css`
    /*** transition|animation ***/
    animation-delay: calc(${_delayTime} * ${index});
    animation-name: ${fadeIn};
    animation-duration: ${_fadeInTime}; /* アニメーションの長さ */
    animation-fill-mode: forwards; /* アニメーション終了時のスタイルをアニメーション終了後でも適用させる */
  `;
};

export type FadeInUnderLineTextSlotProps = {
  text?: {
    style?: SerializedStyles;
  };
};

type Props = {
  cssOverride?: SerializedStyles;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  delayTime?: number;
  fadeInTime?: number;
  index: number;
  slotProps?: FadeInUnderLineTextSlotProps;
};

export const FadeInUnderLineText: React.FC<Props> = ({
  cssOverride,
  onClick,
  children,
  delayTime = 0.2,
  fadeInTime = 2.1,
  index,
  slotProps,
}: Props) => {
  return (
    <span css={[cssOverride, styles, animation(delayTime, fadeInTime, index)]}>
      <UnderLineButton onClick={onClick}>
        <EngTitle cssOverride={slotProps?.text?.style}>{children}</EngTitle>
      </UnderLineButton>
    </span>
  );
};
