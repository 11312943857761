/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { HamburgerButton } from "components/atoms/HamburgerButton/HamburgerButton";
import {
  LinkSymbolLogo,
  LinkSymbolLogoProps,
} from "components/atoms/LinkSymbolLogo/LinkSymbolLogo";
import React from "react";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByWindow } from "utils/Animation/FadeInOnScrollByWindow";
import { ChangeThemeButton } from "../../atoms/ChangeThemeButton/ChangeThemeButton";

const POS_ABSOLUTE_MOBILE_RIGHT_BUTTON = defaultTheme.spacing.base.x.mobile;
const POS_ABSOLUTE_PC_RIGHT_BUTTON = defaultTheme.spacing.base.x.pc;

export type HeaderProps = {
  slotProps: {
    logo: {
      dark: boolean;
    };
    hamburgerButton: {
      onClick: React.MouseEventHandler<HTMLButtonElement>;
      open: boolean;
      color: string;
      openColor: string;
    };
    changeThemeButton: {
      dark?: boolean;
      styleProps?: {
        light?: {
          color?: string;
        };
        dark?: {
          color?: string;
        };
      };
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
  };
  id?: string;
};

const StyledHeaderLogo: React.FC<LinkSymbolLogoProps> = (
  props: LinkSymbolLogoProps
) => (
  <LinkSymbolLogo
    {...props}
    cssOverride={css`
      position: fixed;
      top: calc(
        ${defaultTheme.spacing.header.top} - 0.2rem
      ); // MEMO-9xkjadlkadfs: 縦方向の中央揃えのため調整

      left: ${defaultTheme.spacing.base.x.mobile};
      @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
        left: ${defaultTheme.spacing.base.x.pc};
      }
    `}
  />
);

export const Header: React.FC<HeaderProps> = ({
  slotProps,
  id = "header",
}: HeaderProps) => {
  const { dark: logoDark } = slotProps.logo;
  const {
    onClick: onHamburgerButtonClick,
    open,
    color,
    openColor,
  } = slotProps.hamburgerButton;
  const {
    dark: changeThemeDark,
    onClick: onChangeThemeClick,
    styleProps,
  } = slotProps.changeThemeButton;

  return (
    <div id={id}>
      <FadeInOnScrollByWindow
        positionY={100}
        fadeInSec={0.7}
        cssOverride={css`
          position: relative;
          z-index: ${defaultTheme.zIndex.headerLogo};
        `}
      >
        {logoDark ? <StyledHeaderLogo dark /> : <StyledHeaderLogo />}
      </FadeInOnScrollByWindow>

      <HamburgerButton
        cssOverride={css`
          z-index: ${defaultTheme.zIndex.hambugerButton};
          position: fixed;
          top: ${defaultTheme.spacing.header.top};
          right: ${POS_ABSOLUTE_MOBILE_RIGHT_BUTTON};

          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            right: ${POS_ABSOLUTE_PC_RIGHT_BUTTON};
          }
        `}
        onClick={onHamburgerButtonClick}
        open={open}
        color={color}
        openColor={openColor}
      />

      <ChangeThemeButton
        cssOverride={css`
          z-index: ${defaultTheme.zIndex.changeThemeButton};
          position: fixed;
          top: calc(
            ${defaultTheme.spacing.header.top} + 0.37rem
          ); // MEMO-9xkjadlkadfs: 縦方向の中央揃えのため調整

          right: calc(${POS_ABSOLUTE_MOBILE_RIGHT_BUTTON} * 3.25);
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            right: calc(${POS_ABSOLUTE_PC_RIGHT_BUTTON} * 1.85);
          }
        `}
        dark={changeThemeDark}
        onClick={onChangeThemeClick}
        styleProps={styleProps}
      />
    </div>
  );
};
