/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Panel } from "components/atoms/FadeInText/FadeInText";
import { SlashLineSeparator } from "components/atoms/SlashSeparator/SlashLineSeparator";
import {
  HomeTemplate,
  HomeTemplateSlotProps,
} from "components/templates/HomeTemplate/HomeTemplate";
import { useTheme } from "hooks/useTheme";
import React, { useMemo } from "react";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";
import { MainLayout } from "../MainLayout/MainLayout";

const delayTimeSec = 0.2; // リストアイテムの表示ごとに加算されていくディレイ

const Separator: React.FC = () => (
  <SlashLineSeparator mx={defaultTheme.spacing.xxs} />
);

const PanelItem: React.FC<{ text: React.ReactNode; hideSeparator?: boolean }> =
  ({ text, hideSeparator = false }) => (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <span>{text}</span>
      {!hideSeparator && <Separator />}
    </div>
  );

const panels: Panel[] = [
  { value: <PanelItem text="React" /> },
  { value: <PanelItem text="Next.js" /> },
  { value: <PanelItem text="TypeScript" /> },
  { value: <PanelItem text="AWS" /> },
  { value: <PanelItem text="Terraform" /> },
  { value: <PanelItem text="Go" /> },
  { value: <PanelItem text="Echo" /> },
  { value: <PanelItem text="Laravel" hideSeparator /> },
];

const HomePage: React.FC = () => {
  const { mode, theme } = useTheme();

  const slotProps: HomeTemplateSlotProps = useMemo(
    () => ({
      hero: {
        panels,
        delayTimeSec,
        dark: mode === "dark",
        slotProps: {
          SlashLineSeparatorTexts: {
            textColor: theme.palette.gray.text,
          },
        },
      },
    }),
    [mode]
  );

  return <HomeTemplate id={constants.pages.home.id} slotProps={slotProps} />;
};

const LayoutPage = () => (
  <MainLayout>
    <HomePage />
  </MainLayout>
);
export { LayoutPage as HomePage };
