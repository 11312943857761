/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ComponentLibraryButton } from "components/atoms/ComponentLibraryButton/ComponentLibraryButton";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import {
  FadeInUnderLineList,
  FadeInUnderLineListType,
} from "components/molecules/FadeInUnderLineList/FadeInUnderLineList";
import { SNSTextIconsType } from "components/molecules/SNSTextIcons/SNSTextIcons";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { Overlay } from "../Overlay/Overlay";

type Props = {
  show: boolean;
  slotProps: {
    logo: {
      onLogoClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
    overlay: {
      onOverlayClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
    navItems: {
      items: FadeInUnderLineListType[];
      onNavItemClick?: React.MouseEventHandler<HTMLButtonElement>;
      delayTime?: number;
      fadeInTime?: number;
    };
    footer: {
      iconProps: SNSTextIconsType;
      uiLibraryLink?: string;
    };
  };
};

export const NavMenuV2: React.FC<Props> = ({ show, slotProps }: Props) => {
  const { onLogoClick } = slotProps.logo;
  const { onOverlayClick } = slotProps.overlay;
  const { items, onNavItemClick } = slotProps.navItems;
  const { iconProps, uiLibraryLink = "" } = slotProps.footer;

  return (
    <Overlay
      cssOverride={css`
        z-index: ${defaultTheme.zIndex.overlay};
      `}
      show={show}
      duration={0.5}
      onClick={onOverlayClick}
      color={defaultTheme.palette.common.overlay}
    >
      <BaseInner
        centered={false}
        width="100%"
        maxWidth="100%"
        cssOverride={css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow-y: ${show ? "auto" : "hidden"};
          overflow-x: ${show ? "hidden" : "auto"};

          padding-top: calc(
            ${defaultTheme.spacing.header.top} + 0.24rem
          ); // MEMO-9xkjadlkadfs: 縦方向の中央揃えのため調整
          color: ${defaultTheme.palette.common.white};

          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            margin: 0 auto;
            padding-left: 0;
          }
        `}
      >
        <div
          css={css`
            margin-top: ${defaultTheme.spacing.lg};
            padding: 0 ${defaultTheme.spacing.xs};
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              margin-top: ${defaultTheme.spacing.lg};
              padding: 0;
            }
          `}
        >
          {/* ナビリスト */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: ${defaultTheme.spacing.lg};
            `}
          >
            <FadeInUnderLineList
              items={items}
              onClick={onNavItemClick}
              delayTime={0.125}
              fadeInTime={2}
              slotProps={{
                container: {
                  style: css`
                    row-gap: ${defaultTheme.spacing.xs};
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      row-gap: ${defaultTheme.spacing.sm};
                    }
                  `,
                },
                item: {
                  text: {
                    style: css`
                      ${mixin.text.navMenuItem};
                    `,
                  },
                },
              }}
            />
          </div>
          {/* フッター */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <GradationDivider
              fromColor={defaultTheme.palette.common.white}
              toColor={defaultTheme.palette.gray.button[100]}
              size={defaultTheme.borderWidth.sm}
              cssOverride={css`
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: ${defaultTheme.spacing.md};
                max-width: 240px;
              `}
            />
            {/* ComponentLibrary */}
            <a href={uiLibraryLink} target="_blank" rel="noreferrer">
              <ComponentLibraryButton />
            </a>
          </div>
        </div>
      </BaseInner>
    </Overlay>
  );
};
