/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { ReactNode, useEffect, useState } from "react";

/*** CSS Style ***/
const styles = (props: Props) => {
  return {
    enter: css`
      opacity: 0;
      pointer-events: none;
    `,

    hidden: css`
      transition: ${props.fadeInSec}s ease;
      opacity: 0;
      pointer-events: none;
    `,

    visible: css`
      transition: ${props.fadeInSec}s ease;
      opacity: 1;
      pointer-events: auto;
    `,
  };
};

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: ReactNode;
  positionY?: number;
  fadeInSec?: number;
};
export const FadeInOnScrollByWindow: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  positionY = 100,
  fadeInSec = 0.7,
}: Props) => {
  const Style = {
    Enter: 0,
    Visible: 1,
    Hidden: 2,
  } as const;
  type StyleEnum = typeof Style[keyof typeof Style];

  const [styleType, setStyleType] = useState<StyleEnum>(Style.Enter);

  const toggleVisibility = (): void => {
    if (window.scrollY > positionY) {
      setStyleType(Style.Visible);
      return;
    }

    setStyleType(Style.Hidden);
  };

  // 複数の条件式ごとのスタイル名を取得
  const getAttachClass = (props: Props) => {
    const styles_ = styles(props);

    let baseClass: SerializedStyles[] = [];
    if (styleType === Style.Enter) {
      baseClass.push(styles_.enter);
    } else if (styleType === Style.Visible) {
      baseClass.push(styles_.visible);
    } else if (styleType === Style.Hidden) {
      baseClass.push(styles_.hidden);
    }
    return baseClass;
  };

  useEffect(() => {
    // マウント時の処理
    window.addEventListener("scroll", toggleVisibility);

    // アンマウント時の処理
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <span
      css={[getAttachClass({ fadeInSec }), cssOverride]}
      className={"" + (className ? ` ${className}` : "")}
    >
      {children}
    </span>
  );
};
