import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export const usePageTracking = ({ gaId }: { gaId: string }) => {
  const location = useLocation();

  useEffect(() => {
    if (gaId) {
      // Google Analytics 測定 ID を入力して設定
      ReactGA.initialize(gaId);
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
    }
  }, [location, gaId]);
};
