/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ArrowIcon } from "components/atoms/ArrowIcon/ArrowIcon";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";
import { CustomScroll } from "utils/Wrapper/Scroll/CustomScroll";

export type PageTopProps = {
  bgColor?: string;
  color?: string;
  cssOverride?: SerializedStyles;
};

export const PageTop: React.FC<PageTopProps> = ({
  bgColor = "#444444",
  color = "#ffffff",
  cssOverride,
}) => {
  return (
    <FullWidthOuter bgColor={bgColor} cssOverride={cssOverride}>
      <CustomScroll
        toWindowTop
        toId=""
        cssOverride={css`
          display: flex;
          justify-content: center;
          align-items: center;
          gap: ${defaultTheme.spacing.xxs};
          margin: 0 auto;
          padding: ${defaultTheme.spacing.xxs} 0;
        `}
      >
        <ArrowIcon
          color={color}
          direction="up"
          weight={2.5}
          size={3.5}
          css={css`
            position: relative;
            top: 2px;
          `}
        />
        <span
          css={css`
            ${mixin.changeThemeColor};
            ${mixin.text.body};
            color: ${color};
          `}
        >
          このページのトップへ
        </span>
      </CustomScroll>
    </FullWidthOuter>
  );
};
