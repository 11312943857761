/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

const styles = css`
  ${mixin.text.description}
  letter-spacing: 0.2em;
  /*letter-spacing: ${defaultTheme.letterSpacing.xs};*/
  white-space: nowrap;
  text-transform: uppercase;
`;

type Props = {
  cssOverride?: SerializedStyles;
};

export const SelfIntroductionText: React.FC<Props> = ({ cssOverride }) => {
  return <div css={[cssOverride, styles]}>Web Engineer</div>;
};
