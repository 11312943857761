/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  open?: boolean;
  color?: string;
  openColor?: string;
  size?: number;
  duration?: number;
  margin?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

// ハンバーガーボタン全体
const stylesHamburger = (props: Props): SerializedStyles => {
  return css`
    width: calc(26px * (${props.size} / 10));
    height: calc(40px * (${props.size} / 10));
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  `;
};

// ハンバーガーの3本線全体
const stylesLines = (props: Props): SerializedStyles => {
  return css`
    position: absolute;

    // 3本線の横幅と線の太さ
    width: calc(25px * (${props.size} / 10));
    height: calc(2px * (${props.size} / 10));
  `;
};

const stylesLines_before = (props: Props): SerializedStyles => {
  return css`
    background: ${props.color}; // 線の色
    transition: ${props.duration}s ease;
  `;
};
const stylesLines_after = (props: Props): SerializedStyles => {
  return css`
    background: ${props.openColor}; // 線の色
    transition: ${props.duration}s ease;
  `;
};

// ハンバーガーの3本線(個別)
const stylesLine1_before = (props: Props): SerializedStyles => {
  const lineNumber = 1;
  return css`
    top: calc(${props.margin}px * ${lineNumber});
  `;
};
const stylesLine2_before = (props: Props): SerializedStyles => {
  const lineNumber = 2;
  return css`
    top: calc(${props.margin}px * ${lineNumber});
  `;
};
const stylesLine3_before = (props: Props): SerializedStyles => {
  const lineNumber = 3;
  return css`
    top: calc(${props.margin}px * ${lineNumber});
  `;
};
const stylesLine1_after = (): SerializedStyles => {
  return css`
    transform: translateY(10px) rotate(-45deg); // 左下斜め
  `;
};
const stylesLine2_after = (): SerializedStyles => {
  return css`
    opacity: 0;
  `;
};
const stylesLine3_after = (): SerializedStyles => {
  return css`
    transform: translateY(-10px) rotate(45deg); // 右下斜め
  `;
};

export const HamburgerButton: React.FC<Props> = ({
  className,
  cssOverride,
  open = false,
  color = "#444444",
  openColor = "#444444",
  size = 10,
  duration = 0.5,
  margin = 10,
  ...rest
}: Props) => {
  return (
    <button
      {...rest}
      css={[stylesHamburger({ size }), cssOverride]}
      className={"" + (className ? ` ${className}` : "")}
    >
      <span
        css={[
          stylesLines({ size, color }),
          stylesLines_before({ color, duration }),
          open && stylesLines_after({ openColor, duration }),
          stylesLine1_before({ margin }),
          open && stylesLine1_after,
        ]}
      />
      <span
        css={[
          stylesLines({ size, color }),
          stylesLines_before({ color, duration }),
          open && stylesLines_after({ openColor, duration }),
          stylesLine2_before({ margin }),
          open && stylesLine2_after,
        ]}
      />
      <span
        css={[
          stylesLines({ size, color }),
          stylesLines_before({ color, duration }),
          open && stylesLines_after({ openColor, duration }),
          stylesLine3_before({ margin }),
          open && stylesLine3_after,
        ]}
      />
    </button>
  );
};
