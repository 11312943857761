/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Panel } from "components/atoms/FadeInText/FadeInText";
import { SelfIntroductionText } from "components/atoms/SelfIntroductionText/SelfIntroductionText";
import { VerticalSymbolTextLogo } from "components/atoms/VerticalSymbolTextLogo/VerticalSymbolTextLogo";
import { FadeInTexts } from "components/molecules/FadeInTexts/FadeInTexts";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";

type Props = {
  panels: Panel[];
  delayTimeSec?: number;
  dark?: boolean;
  slotProps?: {
    SlashLineSeparatorTexts: {
      textColor: string;
    };
  };
};
export const LogoHero: React.FC<Props> = ({
  panels,
  delayTimeSec = 0.2,
  dark = false,
  slotProps,
}: Props) => {
  const styles = {
    fadeInTextItems: css`
      ${mixin.text.description};
      padding-top: 0.25rem;
      line-height: 1.75rem; /* 28px */
      letter-spacing: ${defaultTheme.letterSpacing.xs};
      text-transform: uppercase;
      color: ${slotProps?.SlashLineSeparatorTexts.textColor};

      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    `,
  };

  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
      `}
    >
      <h1>
        <FadeInOnScrollByComponent
          delay={defaultTheme.delay.none}
          direction="spot"
        >
          <VerticalSymbolTextLogo
            dark={dark}
            cssOverride={css`
              margin-bottom: 0.5rem;
            `}
          />
        </FadeInOnScrollByComponent>
      </h1>
      <h2>
        <FadeInOnScrollByComponent
          delay={defaultTheme.delay.short}
          direction="spot"
        >
          <SelfIntroductionText
            cssOverride={css`
              margin-bottom: 2rem;
            `}
          />
        </FadeInOnScrollByComponent>
      </h2>
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.none}
        direction="spot"
      >
        <FadeInTexts
          panels={panels}
          delayTimeSec={delayTimeSec}
          cssOverride={styles.fadeInTextItems}
        />
      </FadeInOnScrollByComponent>
    </section>
  );
};
