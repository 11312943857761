/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GitHubIcon } from "components/atoms/GitHubIcon/GitHubIcon";
import { defaultTheme } from "styles/theme";

const styles = {
  icon: css`
    &:hover {
      opacity: 0.8;
    }
  `,
};

export type SNSSymbolIconProps = {
  github: {
    href: string;
  };
};

export type SNSSymbolIconsProps = {
  color?: string;
  size?: "small" | "medium";
  iconProps: SNSSymbolIconProps;
};

export const SNSSymbolIcons: React.FC<SNSSymbolIconsProps> = ({
  color = "#666666",
  size = "medium",
  iconProps,
}: SNSSymbolIconsProps) => {
  const { github } = iconProps;

  return (
    <div
      css={css`
        display: flex;
        gap: ${defaultTheme.spacing.xs};
      `}
    >
      {/* githubアイコン */}
      <a href={github.href} css={styles.icon} target="_blank" rel="noreferrer">
        <GitHubIcon
          color={color}
          size={size === "small" ? 18 : 22}
          cssOverride={css`
            position: relative;
            top: -0.75px;
          `}
        />
      </a>
    </div>
  );
};
